:root {
  --themeFont: helvatica, circular, slack circular pro, tahoma, roboto;
  --themeSizeSmall: 12px;
  --themeSize: 15px;
  --themeSizeLarge: 28px;
  
  --success: #E57373;
  --info: #66BB6A;
  --warning: #26A69A;
  --error: #E8F5E9;

  --body: #ffffff;
  --paper: #e0e0e0;
  --text: #1F154F;
  --link: #1F1E58;

  --primary: #C8E6C9;
  --primaryText: #004D40;
  --secondary: #1B5E20;
  --secondaryText: #FFFFFF;

  --header: #A5D6A7;
  --headerText: #004D40;
  --footer: #A5D6A7;
  --footerText: #004D40;
  --stripe: #43A047;
  --stripeText: #FFFFFF;
}

body {
  font-family: var(--themeFont) !important;
  color: var(--text) !important;
  background-color: var(--body) !important;
  font-size: var(--themeSize) !important;
}

header, footer, nav {
  font-family: var(--themeFont) !important;
  color: var(--headerText) !important;
  background-color: var(--header) !important;
  font-size: var(--themeSize) !important;
  height: fit-content !important;
}
.themeColor {
  color: var(--header) !important;
}
.themeColor:hover {
  color: var(--header) !important;
}
.themeBg {
  background-color: var(--header) !important;
}
.themeBg:hover {
  background-color: var(--header) !important;
}
.themeTitle {
  font-family: var(--themeFont) !important;
  color: var(--headerText) !important;
  /* background-color: var(--header) !important; */
  font-size: var(--themeSizeLarge) !important;
}
.themeCBFS {
  color: var(--headerText) !important;
  background-color: var(--header) !important;
  font-family: var(--themeFont) !important;
  font-size: var(--themeSize) !important;
}
.themeCBF {
  color: var(--headerText) !important;
  background-color: var(--header) !important;
  font-family: var(--themeFont) !important;
}
.theme {
  color: var(--headerText) !important;
  font-family: var(--themeFont) !important;
}

.bodyTheme {
  color: var(--themeFont) !important;
  background-color: var(--bodyBg) !important;
  font-family: var(--themeFont) !important;
  font-size: var(--bodySize) !important;
}

.stripTheme {
  color: var(--stripeText) !important;
  background-color: var(--stripe) !important;
  font-family: var(--themeFont) !important;
  font-size: var(--stripSize) !important;
}
.stripTheme .MuiTypography-root,
.stripTheme h4 {
  color: var(--stripeText) !important;
  font-family: var(--themeFont) !important;
}

main a {
  text-decoration: none;
  color: var(--link);
}

main a:hover {
  text-decoration: underline;
  color: var(--link);
}

main button {
  /* color: var(--themeFont) !important; */
}

/* add a 3d effect on hover to the loaders */
.loader {
  border: 8px solid #2a2679;
  border-radius: 50%;
  border-top: 8px solid #2a26793d;
  width: 5.5vh;
  height: 5.5vh;
  position: absolute;
  -webkit-animation: spin 2s linear infinite; 
  animation: spin 2s linear infinite;
  transition: all 0.2s ease-in-out;
  box-shadow: 0px 0px 0px 1px #fcf300;
  &:hover {
    -webkit-animation: spin 0.5s linear infinite; 
    animation: spin 0.5s linear infinite;
  }
}

.loader2 {
  border: 1vh solid #db23003d;
  border-radius: 50%;
  border-top: 1vh solid #db2300;
  width: 4.5vh;
  height: 4.5vh;
  position: absolute;
  -webkit-animation: spin2 1.8s linear infinite; 
  animation: spin2 1.8s linear infinite;
  transition: all 0.2s ease-in-out;
  box-shadow: inset 0px 0px 3px 0px white;
  &:hover {
    -webkit-animation: spin2 0.4s linear infinite; 
    animation: spin2 0.4s linear infinite;
  }
}

.cloader {
  border: 8px solid #267938;
  border-radius: 50%;
  border-top: 8px solid #2659793d;
  width: 6vh;
  height: 6vh;
  position: absolute;
  -webkit-animation: spin 2s linear infinite; 
  animation: spin 2s linear infinite;
  transition: all 0.2s ease-in-out;
  box-shadow: 0px 0px 0px 1px #f8f494;
  &:hover {
    -webkit-animation: spin 0.5s linear infinite; 
    animation: spin 0.5s linear infinite;
  }
}

.cloader2 {
  border: 1vh solid #0000db3d;
  border-radius: 50%;
  border-top: 1vh solid #db00a8;
  width: 3vh;
  height: 3vh;
  position: absolute;
  -webkit-animation: spin2 1.8s linear infinite; 
  animation: spin2 1.8s linear infinite;
  transition: all 0.2s ease-in-out;
  box-shadow: inset 0px 0px 3px 0px white;
  &:hover {
    -webkit-animation: spin2 0.4s linear infinite; 
    animation: spin2 0.4s linear infinite;
  }
}

@-webkit-keyframes spin {
  0% { -webkit-transform: rotate(0deg); }
  100% { -webkit-transform: rotate(360deg); }
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

@-webkit-keyframes spin2 {
  0% { -webkit-transform: rotate(360deg); }
  100% { -webkit-transform: rotate(0deg); }
}

@keyframes spin2 {
  0% { transform: rotate(360deg); }
  100% { transform: rotate(0deg); }
}


.App {
  text-align: center;
}
.MuiPaper-root {
  background-color: var(--paper);
  color: var(--text);
  z-index: 100;
}

.MuiTabs-root .MuiTab-root {
  color: var(--text);
  font-size: var(--themeSize);
}

.MuiTabs-root .MuiTab-root.Mui-selected {
  color: var(--text);
  font-size: var(--themeSize);
}


nav .MuiBox-root button {
  font-weight: 700;
  letter-spacing: 1px;
  color: var(--headerText) !important;
  font-size: var(--themeSize) !important;
  text-shadow: 0px 0px 2px var(--stripe);
  -webkit-text-stroke: 0.25px var(--header);
  -webkit-text-fill-color: var(--headerText);
}
nav.MuiPaper-root:hover button {
  text-shadow: none !important;
  -webkit-text-stroke: none !important;
}


nav.MuiPaper-root {
  background-color: transparent !important;
  color: var(--headerText) !important;
  box-shadow: none !important;
  transition: all 0.2s ease-in-out;
}
nav.MuiPaper-root:hover {
  background-color: var(--header) !important;
  box-shadow: 0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12) !important;
}


.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.shadow-image {
  position: relative;
  display: inline-block;
}

.shadow-image::before {
  content: "";
  position: absolute;
  top: 10px;
  left: 10px;
  bottom: 10px;
  right: 10px;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.8);
  transform: rotateY(20deg) rotateX(10deg);
  z-index: -1;
}

.shadow-image img {
  display: block;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(var(--themeSize) + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

* {
  box-sizing: border-box;
}

body {
  margin: 0;
}

/* sx={{ flexGrow: 1, bgcolor: 'transparent', position: 'absolute', bottom: 0, left: 0, right: 0, top:0, transition: '0.3s ease', '&:hover': { bgcolor: 'rgba(255, 255, 255, 0.9)', */
.sponsor {
  flex-grow: 1;
  background-color: transparent;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  top: 0;
  transition: 0.3s ease;
  opacity: 0;
}
.sponsor:hover {
  background-color: var(--footer);
  opacity: 0.9;
}
.sponsor:hover .MuiTypography-root{
  color: var(--footerText);
}

.container {
  margin: 1rem;
  text-align: center;
}

.nav {
  background-color: var(--header);
  color: var(--headerText);
  display: flex;
  justify-content: space-between;
  align-items: stretch;
  gap: 2rem;
  padding: 0 .5rem;
}

.nav li {
  transition: background-color .25s ease-in-out;

}

.site-title {
  font-size: var(--themeSizeLarge)
}

.nav ul {
  padding: 0;
  margin: 0;
  list-style: none;
  display: flex;
  /* gap: 1rem; */
}

.nav a {
  top: 0;
  color: inherit;
  text-decoration: none;
  height: 100%;
  display: flex;
  align-items: center;
  padding: 0 .5rem;
}

.nav li.active {
  background-color: var(--headerText);
}

.nav li:hover {
  background-color: var(--footerText);
}

.swiper-slide-thumb>img {
  border: 2px solid transparent;
  box-shadow: 0px 0px 6px 0px transparent;
}
.swiper-slide-thumb-active>img {
  border: 2px solid var(--footerText);
  box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.4);
}

.MuiBottomNavigation-root {
  background-color: var(--footer);
  color: var(--footerText);
  font-size: var(--themeSize);
  font-family: var(--themeFont);
  /* padding: 1rem; */
  bottom: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-top: 1px solid #ddd;
  
}

.MuiBottomNavigationAction-root {
  min-width: 0;
  padding: 0.5rem;
  color: #333;
}

.MuiBottomNavigation-root .container {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  flex: 1 1 100%;
  justify-content: space-between;
  width: 100%;
  max-width: 1200px;
  margin: 2rem auto;
}

.MuiBottomNavigation-root .container > * {
  flex: 1 1 20%;
  margin: 0.5rem;
}

.MuiBottomNavigation-root .title {
  font-size: var(--themeSizeLarge);
  font-weight: bold;
  margin-bottom: 1rem;
  text-transform: uppercase;
  text-align: justify;
}

.MuiBottomNavigation-root .text {
  font-size: var(--themeSizeSmall);
  /* color: #888; */
  margin-bottom: 1rem;
  text-align: justify;
}

.copyright {
  font-size: var(--themeSizeSmall);
  letter-spacing: 1px;
  margin-top: 1px;
  margin-bottom: 1px;
  /* font-family:  */
  /* background-color: #333; */
  color: #fff;
  /* -webkit-text-stroke: 0.25px #000;
  -webkit-text-fill-color: #fff; */
  text-shadow: 0px 0px 2px #000;
}

.MuiBottomNavigation-root form {
  text-align: justify;
}

.MuiBottomNavigation-root .icon {
  color: var(--footerText);
}

.MuiBottomNavigation-root .button {
  background-color: var(--footer);
  color: var(--footerText);
  padding: 0.5rem 1rem;
  margin-top: 0.5rem;
  border-radius: 50px;
  transition: all 0.2s ease-in-out;
}

.MuiBottomNavigation-root a {
  color: var(--footerText);
  text-decoration: none;
}

.MuiBottomNavigation-root .button:hover {
  background-color: #111;
}


/* swiper */
.mySwiperSimple {
  height: 550px; /* set the desired height */
  display: flex;
  justify-content: center;
  align-items: center;
}

.swiper-slide img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.mySwiperThumbs {
  height: 80px; /* set the desired height */
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  padding: 2px;
  /* box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.4); */
}

.mySwiperThumbs .swiper-slide {
  opacity: 0.7;
  transition: opacity 0.2s ease-in-out;
}

.mySwiperThumbs .swiper-slide-thumb-active {
  opacity: 1;
}

.mySwiperThumbs .swiper-slide-thumb-active img {
  border: 2px solid var(--footerText);
  box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.4);
}
