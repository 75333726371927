.map-h2 {
    /* text-transform: uppercase; */
    font-size: 1.3rem;
    /* padding: 20px; */
    /* padding-left: 10px; */
    text-align: center;
  }
  
  .google-map {
    width: 100%;
    height: 25rem;
  }
  
  .pin {
    display: flex;
    align-items: center;
    width: 180px;
    color: var(--main-blue);
  }
  
  .pin-icon {
    font-size: 5rem;
    color: aliceblue;
  }
  
  .pin-text {
    font-size: 1em;
    color: aliceblue;

  }
  
  @media screen and (min-width: 799px) {
    .google-map {
      height: 20rem;
    }
  
    .map-h2 {
      font-size: 1.6rem;
      font-weight: 400;
    }
  
    .pin {
      width: 6rem;
    }
  
    .pin-icon {
      font-size: 5rem;
      color: aliceblue;
}
  }